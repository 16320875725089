@import "@/assets/styles/styles.scss";































































































































































































































































html {
    height: -webkit-fill-available;
}

#app {
    position: relative;
    min-height: 100vh;
    width: 100%;
    overflow-y: auto;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.page-content-wrap {
    flex-grow: 1;
}

.page-wrap {
    min-height: calc(100% - 112px);
    width: 100%;

    margin: 0 auto;
    overflow-x: hidden;
    background-color: $page-wrap;
}

.page-wrap.is-profile {
    min-height: calc(100% - 176px);
}

.page {
    height: 100%;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
}

.slide-left-enter-active,
.slide-right-enter-active,
.slide-left-leave-active,
.slide-right-leave-active {
    transition: all 0.32s;
}

.slide-left-enter,
.slide-right-enter,
.slide-left-leave-active,
.slide-right-leave-active {
    opacity: 0;
}

.slide-left-enter,
.slide-right-leave-active {
    transform: translateX(80px);
}

.slide-left-leave-active,
.slide-right-enter {
    transform: translateX(-80px);
}

@media (max-width: 749px) {
    .page-wrap {
        min-height: calc(100% - 228px);
    }

    .page-wrap.is-profile {
        min-height: calc(100% - 292px);
    }
}
