@import "@/assets/styles/styles.scss";




















































































































.input-wrapper {
    position: relative;
    margin: 16px 0;
}

.input-wrapper.invalid.currency {
    margin: 16px 0 8px 0;
}

.label {
    color: $grey-lg-text;
    font-size: 14px;
}

.input-wrapper.invalid .label {
    color: #e84c4c;
}

.input {
    height: 38px;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    border-bottom: 1.8px solid #cccccc;
    background: #ffffff !important; // желательно прописывать, так как в Chrome при сохранных данных оно может быть желтым
    outline: none; // удаляет обводку в браузерах хром(желтая) и сафари(синяя)
    color: $grafit; //цвет шрифта в обычном состоянии
}

.input.active {
    border-color: rgb(255, 153, 0);
}

.input.invalid {
    border-color: #e84c4c;
}

.input img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
}

.input input {
    width: 100%;
    font-size: 16px;
    color: $cur-name;
    margin: 0;
    padding: 0 0 2px 0;
    border: none;
    background-image: none;
    background-color: transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

input::-webkit-input-placeholder {
    color: $grey-lg-text;
}

.input.currency {
    height: 48px;
    padding: 0 0 7px 0;
}

.input.currency input {
    width: calc(100% - 64px);
    padding: 12px 16px;
}

.input input:focus {
    outline: none;
}

.input .currency-code {
    width: 32px;
    text-align: center;
    color: $code;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
}

.input-wrapper.invalid .input:not(.currency) input {
    color: #e84c4c;
}

.input-wrapper.invalid .input input::placeholder {
    color: #e84c4c;
    opacity: 1;
}

.input-wrapper.invalid .input input:-ms-input-placeholder {
    color: #e84c4c;
}

.input-wrapper.invalid .input input::-ms-input-placeholder {
    color: #e84c4c;
}

.validation-message {
    color: #e84c4c;
    font-size: 14px;
    line-height: 20px;
    margin-top: 16px;
}

.validation-message:first-letter {
    text-transform: capitalize;
}

.validation-message > .amount {
    font-weight: 500;
}
