@import "@/assets/styles/styles.scss";































































































































































































































































.acquaring_page {
    color: $black;
    font-size: 16px;
    margin-top: 32px;
}

.acquaring_page .content {
    width: 80%;
    margin: 0px auto;
}

.acquaring_page .card {
    background-color: $white;
    border-radius: $border-sm;
    box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.32);
    padding: 24px 24px;
}

.top_self {
    align-self: top;
}
.bottom_self {
    align-self: end;
}

.acquaring_page .top_content_btn {
    margin-top: 24px;
}

.acquaring_page .card_info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 12vw;
    height: 12vw;
    padding: 14px;
}

.acquaring_page .card_info_title {
    text-align: center;
    font-size: 16px;
    color: $grey-lg-text;
    line-height: 22px;
    margin-top: 16px;
}

.acquaring_page .section {
    margin: 32px 0px;
}

.acquaring_page .main_title {
    font-size: 22px;
    line-height: 32px;
    margin-bottom: 24px;
    display: flex;
    color: $grafit;
}

.acquaring_page .main_sub_title {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 18px;
    color: $grafit;
}
.acquaring_page .card_info {
}

.acquaring_page .top_content {
    padding-top: 42px;
    padding-bottom: 42px;
    background-image: url("../assets/a1.png");
    background-repeat: no-repeat;
    background-position: 88% -40%;
    background-size: 33%;
}

.acquaring_page .top_content .title {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 16px;
    display: flex;
    color: $grafit;
}

.acquaring_page .top_content .desc {
    font-size: 16px;
    color: $grey-lg-text;
    line-height: 22px;
    // margin-bottom: 16px;
}

.acquaring_page .features_cards {
    display: flex;
    justify-content: space-between;
    height: 15vw;
    // flex-wrap: wrap;
}

.acquaring_page .sphere_cards {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.acquaring_page .card_sphere {
    margin: 0;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}

.acquaring_page .card_sphere:nth-child(odd) {
    width: calc(50% - 8px);
}

.acquaring_page .card_sphere:nth-child(even) {
    width: calc(50% - 8px);
    // width: calc(50% - 24px);
}

.acquaring_page .card_sphere_title {
    font-size: 18px;
    color: $grey-lg-text;
    line-height: 22px;
    margin-left: 16px;
    font-weight: 500;
}

.acquaring_page .currency_text {
    font-size: 16px;
    color: $grey-lg-text;
    line-height: 22px;
    background-image: url("../assets/a2.png");
    background-repeat: no-repeat;
    //   background-attachment: fixed;
    background-position: 85% 23%;
    background-size: 34%;
}

.acquaring_page .form_title {
    text-align: center;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 12px;
    color: $grafit;
}

.acquaring_page .form_btn_wrap {
    display: flex;
    justify-content: center;
}
.acquaring_page .form_btn {
    padding: 14px 40px;
}

.acquaring_page .form_field {
    min-height: 54px;
    box-sizing: border-box;
}

@media (max-width: 1000px) {
    .acquaring_page .top_content {
        background-position: 97%;
        background-size: 40%;
    }

    .acquaring_page .currency_text {
        background-position: 116% 0%;
        background-size: 50%;
    }

    .acquaring_page .features_cards {
        height: auto;
        flex-wrap: wrap;
        justify-content: space-around;
    }
    .acquaring_page .card_info {
        width: 160px;
        height: 160px;
        margin-bottom: 35px;
        // width: 24vw;
        // height: 24vw;
    }
    .acquaring_page .card_sphere {
        width: 100% !important;
    }

    .acquaring_page .form_btn {
        width: 100%;
    }
}

@media (max-width: 700px) {
    .acquaring_page .content {
        width: auto;
        box-sizing: border-box;
        margin: 8px;
        padding: 24px 16px;
        margin-top: 0px;
        padding-top: 0px;
    }

    .acquaring_page .top_content {
        background-position: 122% 135%;
        background-size: 50%;
    }

    .acquaring_page .features_cards {
        display: flex;
        flex-wrap: wrap;
    }

    .acquaring_page .features_cards .card_info {
        width: calc(50% - 10px);
        margin: 5px;
        box-sizing: border-box;
    }
    .acquaring_page .currency_text {
        background-position: 130% -30%;
        background-size: 50%;
    }
}
