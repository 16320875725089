@import "@/assets/styles/styles.scss";





















.orderPaymentSum {
    display: flex;
    align-items: center;
    margin-top: 16px;
    margin-left: 16px;
}

.orderPaymentSum_name {
    font-size: 18px;
}
.orderPaymentSum_value {
    font-weight: 600;
    display: flex;
    align-items: center;
    word-break: break-all;
    font-size: 22px;
}

.orderPaymentSum_value span {
    margin-right: 5px;
}
