@import "@/assets/styles/styles.scss";























.orderFields {
    margin: 20px 0px;
}

.orderFields_item {
    margin-bottom: 10px;
    // display: flex;
    // align-items: center;
    // flex-wrap: wrap;
}

.orderFields_itemName {
}
.orderFields_itemValue {
    font-weight: 600;
    display: flex;
    align-items: center;
    word-break: break-all;
}
.orderFields_itemValue span {
    margin-right: 5px;
}
