@import "@/assets/styles/styles.scss";
















































































































































































.orderPaymentSection {
    background-color: #0000000d;
    padding: 18px 22px;
    border-radius: 8px;
    margin-top: 20px;
}
.orderPaymentSection_chat {
    margin: 10px 0px 20px;
}

.orderPaymentSection_chat span {
    cursor: pointer;
    color: #ff8c00;
}
.orderPaymentSection_chat span:hover {
    text-decoration: underline;
}

.orderPaymentSection_notify {
    color: #ff8c00;
    margin: 15px 0px;
    background-color: white;
    border-radius: 10px;
    padding: 10px 15px;
}

.orderPaymentSection_qrLayout {
    display: flex;
    justify-content: center;
    align-items: center;
}

.orderPaymentSection_qrWrap {
    background-color: white;
    padding: 10px;

    width: max-content;

    border-radius: 5px;
}

.orderPaymentSection_btns {
    display: flex !important;
    justify-content: center;
    margin-top: 20px;
}

.orderPaymentSection_btns button:first-child {
    margin-right: 10px;
    display: inline-block;
}
.orderPaymentSection_btns button:last-child {
    margin-left: 10px;
    display: inline-block;
}
