@import "@/assets/styles/styles.scss";






























.header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: 16px 16px 0px 16px;
}
.header > .spinner {
    height: 32px;
    width: 32px;
    margin-right: 16px;
}
.header-name {
    font-size: 24px;
    line-height: 30px;
    color: #565656 !important;
}
.icon-close {
    cursor: pointer;
    color: $black;
}
.border {
    height: 1px;
    width: 100%;
    background-color: #ebeff6;
    margin: 12px 0;
    border-radius: 4px;
}
