@import "@/assets/styles/styles.scss";




























































































































































































.cooopearation-signup {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 500px;
}

@media (max-width: 600px) {
    .cooopearation-signup {
        width: auto;
    }
}

.cooopearation-signup_close {
    align-self: flex-end;
    cursor: pointer;
}

.cooopearation-signup_form {
    text-align: left;
}

.cooopearation-signup_agree {
    margin: 20px 2px 10px 2px;
}
.cooopearation-signup_agree-error {
    color: #ff1a1a;
    margin: 18px 0;
}

.cooopearation-signup_form-submit {
    width: 100%;
    margin-top: 20px;
}
.wrapper {
    padding: 0rem 1rem 1rem 1rem;
    background-color: #fff;
    color: #4d4d4d;
}
.partner-header {
    font-size: 24px;
    margin-bottom: 32px;
    color: #161616;
}
