@import "@/assets/styles/styles.scss";







.cardPhotoExample {
    display: block;
    width: 45%;
    margin: 20px auto;
}
