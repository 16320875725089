@import "@/assets/styles/styles.scss";


































































.select {
    position: relative;
    font-family: "Gotham Pro", -apple-system, system-ui, BlinkMacSystemFont, “Segoe UI”, Roboto, “Helvetica Neue”, Arial, sans-serif;
    margin: 16px 0;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}
.selected {
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 16px;
    color: $grafit;
    margin: 0;
    padding: 12px 0;
    border-bottom: 1.2px solid rgba(0, 0, 0, 0.12);
}
.selected .image {
    margin-left: 1rem;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    margin-right: 16px;
    -o-object-fit: cover;
    object-fit: cover;
}
.options {
    width: 100%;
    position: absolute;
    background-color: $white;
    padding: 4px 0;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.32);
    z-index: 2;
}
.option {
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 15px;
    color: $grafit;
    padding: 8px 16px;
    margin: 8px 0;
}
.option:hover {
    background-color: #ff88002b;
}
.option .image {
    height: 24px;
    width: 24px;
    border-radius: 50%;
    margin-right: 16px;
    -o-object-fit: cover;
    object-fit: cover;
}
