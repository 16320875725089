@import "@/assets/styles/styles.scss";



















.rejectedSection {
    text-align: center;
    margin-top: 10px;
}
