@import "@/assets/styles/styles.scss";























.resolvedCardSection {
    font-size: 16px;
    line-height: 22px;
    color: #252525;
}
