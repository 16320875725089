@import "@/assets/styles/styles.scss";



































































































































































































































































































































































































































































$number-of-options: 6;
.amountService {
    display: block;
    margin: 32px 16px;
    cursor: pointer;
    text-decoration: underline;
    color: #727272;
    &:hover {
        color: #3597fa;
    }
}
.priceService {
    margin: 32px 16px;
    padding: 8px;
    border: 1px solid #727272;
    border-radius: 4px;
    color: #565656;
    line-height: 1.2;
}
#form-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
form {
    width: 100%;
    max-width: 500px;
    #form-title {
        margin-top: 0;
        font-weight: 400;
        text-align: center;
    }
    #debt-amount-slider {
        display: flex;
        flex-direction: row;
        align-content: stretch;
        position: relative;
        width: 100%;
        height: 50px;
        user-select: none;
        &::before {
            content: " ";
            position: absolute;
            height: 2px;
            width: 100%;
            width: calc(100% * (#{$number-of-options - 1} / #{$number-of-options}));
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background: #727272;
        }
        input,
        label {
            box-sizing: border-box;
            flex: 1;
            user-select: none;
            cursor: pointer;
        }
        label {
            display: inline-block;
            position: relative;
            width: 20%;
            height: 100%;
            user-select: none;
            &::before {
                content: attr(data-debt-amount);
                position: absolute;
                left: 50%;
                padding-top: 10px;
                transform: translate(-50%, 45px);
                font-size: 14px;
                letter-spacing: 0.4px;
                font-weight: 400;
                white-space: nowrap;
                opacity: 0.85;
                transition: all 0.15s ease-in-out;
            }
            &::after {
                content: " ";
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                width: 24px;
                height: 24px;
                border: 1px solid #727272;
                background: #fff;
                border-radius: 50%;
                pointer-events: none;
                user-select: none;
                z-index: 1;
                cursor: pointer;
                transition: all 0.15s ease-in-out;
            }
            &:hover::after {
                transform: translate(-50%, -50%) scale(1.25);
            }
        }
        input {
            display: none;
            &:checked {
                + label::before {
                    font-weight: 800;
                    opacity: 1;
                }
                + label::after {
                    border-width: 2px;
                    transform: translate(-50%, -50%) scale(0.75);
                }
                ~ #debt-amount-pos {
                    opacity: 1;
                }
                @for $i from 1 through $number-of-options {
                    &:nth-child(#{$i * 2 - 1}) ~ #debt-amount-pos {
                        left: #{($i * 16.666666667%) - 8.3%};
                    }
                }
            }
        }
        #debt-amount-pos {
            display: block;
            position: absolute;
            top: 50%;
            width: 12px;
            height: 12px;
            background: #3597fa;
            border-radius: 50%;
            transition: all 0.15s ease-in-out;
            transform: translate(-50%, -50%);
            border: 1px solid #fff;
            opacity: 0;
            z-index: 2;
        }
    }
    &:valid {
        #debt-amount-slider {
            input {
                + label::before {
                    transform: translate(-50%, 45px) scale(0.9);
                    transition: all 0.15s linear;
                }
                &:checked + label::before {
                    transform: translate(-50%, 45px) scale(1.1);
                    transition: all 0.15s linear;
                }
            }
        }
    }
}
@keyframes spin {
    from {
        transform: rotate(0deg);
        width: 24px;
        opacity: 1;
        margin-right: 12px;
    }
    to {
        transform: rotate(360deg);
        width: 24px;
        opacity: 1;
        margin-right: 12px;
    }
}
.exchanger {
    position: relative;
    display: flex;
    flex-direction: column;
}
.technical-break {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(100% - 32px);
    // position: absolute;
    background-color: $white;
    border-radius: 6px;
    padding: 8% 16px 16px 16px;
    margin: 16px;
    opacity: 0.88;
    z-index: 998;
}
.technical-break > .image {
    width: 100%;
    max-width: 240px;
    margin-bottom: 48px;
}
.technical-break > .title {
    text-align: center;
    color: #323232;
    font-size: 32px;
    margin-bottom: 24px;
}
.technical-break > .date {
    text-align: center;
    color: #323232;
    font-size: 20px;
    margin-bottom: 16px;
}
.technical-break > .sub-title {
    text-align: center;
    max-width: 640px;
    color: #323232;
    font-size: 18px;
    line-height: 24px;
}
.step-wrap {
    width: 100%;
}
.step {
    background-color: $white;
    border-radius: $border-sm;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.1);
}
.step .checkbox {
    margin: 24px 16px 0 16px;
}
.step .button {
    width: calc(100% - 32px);
    margin: 32px 16px 16px 16px;
}
.step-header {
    display: flex;
    flex-direction: column;
    padding: 16px 16px 8px 16px;
}
.step-name {
    margin: 0 auto;
    font-size: 24px;
    color: $grafit;
}
.step-sub-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: $step-sub-header;
    font-size: 14px;
    font-weight: 500;
    padding: 0 16px 8px 16px;
}
.step-sub-header .reserves {
    color: #727272;
    font-weight: 400;
}
.step-sub-header.inputs {
    padding: 24px 16px 0 16px;
}
.step-details .input-wrapper {
    margin: 16px;
}
.step-details .step-header {
    border-bottom: 1.2px solid rgba(0, 0, 0, 0.12);
}
.step-details .currency {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}
.step-details .currency.take {
    padding-top: 32px;
}
.step-details .currency .image {
    object-fit: cover;
    height: 32px;
    width: 32px;
    border-radius: 50%;
    margin-right: 16px;
}
.step-details .currency .details {
    padding-top: 4px;
}
.step-details .currency .details .amount {
    color: $cur-name;
    font-size: 18px;
    font-weight: 500;
    display: flex;
}
.step-details .currency .details .amount .code {
    font-weight: 400;
    margin-left: 0.5rem;
}
.step-details .currency .details .name {
    color: $cur-name;
    font-size: 16px;
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
}
.step-details .icon-exchange {
    color: $cur-name;
    padding-left: 4px;
    margin-bottom: 4px;
}
.step-details .rate-with-commission {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: $cur-name;
    padding: 24px 0 8px 0;
}
.step-details .rate-with-commission .commission .code {
    color: $code;
}
.step-details .rate-with-commission .label {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: #4f4f4f;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 12px;
}
.step-details .rate-with-commission > .rate,
.step-details .rate-with-commission .commission {
    font-weight: 500;
}
.step-details .rate-with-commission > .rate .code,
.step-details .rate-with-commission .commission .code {
    font-weight: 400;
    color: $code;
}
.code {
    color: $code;
}
.step-details .rate-with-commission > .rate i {
    margin: 0 4px;
    color: $grafit;
}
.step-details .notification {
    color: #20459b;
    font-size: 14px;
    line-height: 22px;
    padding: 4px 16px 0 16px;
    margin-bottom: -4px;
}

.step-details {
    transition: opacity 0.2s;
}
.currencyFilter {
    display: flex;
    padding: 8px 16px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: start;
    justify-content: flex-start;
    margin-bottom: 1rem;
}
.filterItem {
    padding: 0.2rem 0.7rem;
    color: $grafit;
    border-radius: $border-sm;
    border: 1.4px solid #cccccc;
    margin-right: 0.4rem;
    position: relative;
    background-color: $white;
    line-height: 1.4;
    cursor: pointer;
    -webkit-transition: border-color 0.2s ease, box-shadow 0.2s ease, -webkit-transform 0.2s ease;
    transition: transform 0.2s ease, border-color 0.2s ease, box-shadow 0.2s ease, -webkit-transform 0.2s ease;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    &:hover {
        box-shadow: 0 1px 4px rgba(95, 95, 95, 0.53);
        -webkit-transform: translateY(-2px);
        transform: translateY(-2px);
        cursor: pointer;
    }
    &.active {
        box-shadow: 0 1px 4px rgba(95, 95, 95, 0.53);
        -webkit-transform: translateY(-2px);
        transform: translateY(-2px);
        border: 1.5px solid $orange;
    }
}
.qiwi-county {
    display: flex;
    align-items: center;
    align-self: center;
    height: 50px;
    margin-top: 1rem;
}
.flag {
    max-width: 24px;
    max-height: 24px;
    margin-left: 1rem;
}
.qiwi-input {
    width: 100%;
}
.checkbox {
    display: block;
    position: relative;
    // padding-left: 36px;
    cursor: pointer;
    font-size: 14px;
    line-height: 20px;
    color: #646464;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: flex;
    justify-content: space-between;
}

.checkbox img {
    transform: rotate(90deg);
    width: 20px;
    transition: transform 0.5s;
}

.checkbox:hover {
    img {
        opacity: 0.8;
    }
}

.checkbox.activeCupon img {
    transform: rotate(-90deg);
}
.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #ebeff6;
    border-radius: 2px;
}
.checkbox input:checked ~ .checkmark {
    background-color: #3597fa;
}
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}
.checkbox input:checked ~ .checkmark:after {
    display: block;
}
.checkbox .checkmark:after {
    left: 7px;
    top: 4px;
    width: 6px;
    height: 9px;
    border: solid #ffffff;
    border-width: 0 1.6px 1.6px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
.checkbox .regulations {
    text-decoration: underline;
}
.checkbox .regulations:hover {
    color: #3597fa;
}
@media (max-width: 739px) {
    .technical-break {
        padding: 32px 16px 16px 16px;
    }
    .technical-break > .image {
        max-width: 180px;
    }
    .step-wrap {
        padding: 4px;
    }
    .step {
        width: calc(100% - 8px) !important;
        margin: 4px !important;
    }
    .step-name {
        margin: 0;
    }
}

.agree {
    display: block;
}

.cupon {
    overflow: hidden;
    height: 0px;
    transition: height 0.5s;
}

.showCupon {
    height: 42px;
}

.city-section {
    margin-top: 20px;
    display: flex;
    width: 100%;
    align-items: center;
    color: #565656;
}

.city-section_name {
    margin-right: 5px;
}

.city-section_value {
    font-weight: 600;
}

.step-details.blured-section,
.step.blured-section {
    opacity: 0.7;
    pointer-events: none;
}
.captcha {
    margin: 24px 16px;
}
