@import "@/assets/styles/styles.scss";


























































.resolvedSection {
    padding: 0px 16px 16px 16px;
    margin-top: 15px;
}

.resolvedSection .textLine {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
}

.resolvedSection .link {
    color: #ff8c00;
    display: flex;
    align-items: center;
    margin-left: 5px;
}

.resolvedSection .link:hover {
    text-decoration: underline;
}

.resolvedSection .wishText {
    margin-right: 5px;
    display: flex;
}

.resolvedSection .thank {
    text-align: center;
}

.resolvedSection .qr {
    text-align: center;
    font-weight: 600;
}
