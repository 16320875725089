@import "@/assets/styles/styles.scss";
@import 'variables.scss';


.my-toast-container .Vue-Toastification__toast--info {
    background-color: $primary-light;
}

.my-toast-container .Vue-Toastification__close-button {
    align-self: flex-start;
}


.chat-and-input .chat-typing__inner {
    display: none;
}
// 
