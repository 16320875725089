@import "@/assets/styles/styles.scss";































































.create_order-promocode-section {
    padding: 4px 16px;
}

.create_order-promocode-section label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #565656;
}

.create_order-promocode-section label .title {
    font-size: 16px;
}

.create_order-promocode-section label .show-arrow {
    transform: rotate(90deg);
    transition: transform 0.2s;
    width: 20px;
}
.create_order-promocode-section label .show-arrow.visible {
    transform: rotate(-90deg);
}
.create_order-promocode-section .promo-section {
    transition: height 0.2s;
    overflow: hidden;
    height: 0px;
}

.create_order-promocode-section .promo-section-show {
    height: 100px;
}

.create_order-promocode-section .promocode-error {
    color: red;
    font-size: 14px;
}
