@import "@/assets/styles/styles.scss";

















.notif-error {
    color: red;
    font-size: 14px;
    padding: 16px;
}

.notif-error p {
    margin-bottom: 8px;
}
