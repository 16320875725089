@import "@/assets/styles/styles.scss";









.cardBannedText {
    text-align: center;
    font-size: 16px;
    margin-top: 10px;
}
