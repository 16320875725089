@import "@/assets/styles/styles.scss";




































































































































































































































































































.auth-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: fixed;
    height: 0;
    width: 0;
    top: 0;
    right: 0;
    background-color: $auth-wrapper;
    transition: width 0.5s, height 0.5s;
    z-index: 999;
    box-shadow: -2px 0 2px -2px rgba(0, 0, 0, 0.16);
    overflow-x: hidden;
    overflow-y: auto;
}

.auth-form {
    padding: 24px 32px;
}

.header-name {
    color: $grafit;
    font-size: 28px;
    display: flex;
    justify-content: center;
    margin-bottom: 32px;
}

.icon-close {
    cursor: pointer;
    position: absolute;
    top: 20px;
    left: 24px;
    color: $black;
}

.white {
    color: $white;
}

.button {
    margin: 8px 0 0 0 !important;
}

.actions-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    color: #5c83e6;
    font-size: 14px;
    margin: 24px 0 8px 0;
}

.signUp-link {
    color: $code;
    cursor: pointer;
    font-weight: 500;
    text-decoration: underline;
}

.reset-password {
    color: $code;
    cursor: pointer;
}

.signUp-link,
.reset-password {
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}

.signUp-link:hover,
.reset-password:hover {
    color: $black;
    transition: 0.5s;
}

.checkbox {
    margin: 24px 0 16px 0 !important;
}

@media (max-width: 479px) {
    .auth-wrapper {
        min-width: 0;
    }
}
