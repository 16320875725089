@import "@/assets/styles/styles.scss";









































.modalContentWrap {
    min-width: 480px;
    max-width: 480px;
    color: #252525;
}

.modalContentWrap_divider {
    height: 1px;
    width: 100%;
    background-color: #ebeff6;
    border-radius: $border-sm;
}

.modalContentWrap_header {
    color: #252525;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: 16px 16px 12px 16px;
}

.modalContentWrap_spiner {
    margin-right: 16px;
}

.modalContentWrap_headerName {
    font-size: 24px;
    line-height: 30px;
    color: #565656;
}

.modalContentWrap_iconClose {
    cursor: pointer;
    color: $black;
}

.modalContentWrap_content {
    padding: 16px;
}
.modalContentWrap_btnsWrap {
    padding: 12px 16px;
}

@media (max-width: 480px) {
    .modalContentWrap {
        // padding: 0px 16px 40px 16px;
        max-width: 95vw;
        // max-width: 480px;
        // color: #252525;
    }
}
