@import "@/assets/styles/styles.scss";
















.rejectedCardComment {
    font-size: 14px;
    line-height: 20px;
    color: red;
    text-align: center;
    margin-top: 10px;
}

.rejectedCardComment_text {
    text-align: center;
    margin-top: 10px;
    font-weight: 600;
}
