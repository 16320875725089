@import "@/assets/styles/styles.scss";



































































































































































.addCardSection {
}

.addCardSection_description {
    font-size: 14px;
    line-height: 20px;
    background-color: $white;
    padding: 16px;
}

.addCardSection_description > p {
    margin-bottom: 16px;
}
.addCardSection_btnsWrap {
    display: flex !important;
    justify-content: center;
}

.addCardSection_btnsWrap button:first-child {
    margin-right: 10px;
    display: inline-block;
}
.addCardSection_btnsWrap button:last-child {
    margin-left: 10px;
    display: inline-block;
}
