@import "@/assets/styles/styles.scss";








































.orderClientFields {
    padding: 10px 24px;
}
.orderClientFields .title {
    font-weight: 600;
}
.orderClientFields .currencInfoSection {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.currencInfoSection .currencyImage {
    height: 32px;
    width: 32px;
    border-radius: 50%;
    margin-right: 16px;
    -o-object-fit: cover;
    object-fit: cover;
}

.currencInfoSection .currencyName {
    margin-left: 3px;
    font-size: 16px;
    font-weight: 600;
}

.orderClientFields .fieldsListWrap {
    margin-top: 20px;
}

.fieldsListItem {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    font-size: 15px;
}
.orderClientFields .fieldsListItemLabel {
    margin-right: 5px;
}

.orderClientFields .fieldsListItemValue {
    font-weight: 600;
    word-break: break-all;
}

// .orderClientFields
