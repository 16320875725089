@import "@/assets/styles/styles.scss";














.processStepSection {
    padding: 30px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}
