@import "@/assets/styles/styles.scss";























































































.orderCardReverify {
    background-color: #0000000d;
    // padding: 18px 22px;
    border-radius: 8px;
    margin-top: 20px;
}
.orderCardReverify_sbmt {
    width: 100%;
    margin-top: 20px;
}
