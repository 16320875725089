@import "@/assets/styles/styles.scss";



























































































.contacts {
    color: $black;
    font-size: 16px;
}

.contacts .card {
}

.contacts .card .page-name {
    font-size: 26px;
    line-height: 32px;
    margin-bottom: 32px;
    text-align: center;
    width: 100%;
    color: #565656;
}

.options {
    display: flex;
    flex-direction: row;
    margin-bottom: 32px;
}

.options .option {
    display: flex;
    flex-direction: row;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.1);
    border-radius: $border-norm;
}

//.options .option:not(:last-child) {
//    margin-right: 1rem;
//}

.options .option .icon {
    color: #656565;
    margin-right: 16px;
}

.options .option .info {
    padding: 1rem;
    background-color: $white;
    border-radius: $border-sm;
}

.options .option .info > .title {
    color: #656565;
    font-size: 18px;
    margin-bottom: 1rem;
}

.options .option .info > .sub-title {
    font-size: 18px;
    color: #727272;
}

.description {
    max-width: 640px;
    color: #656565;
    line-height: 22px;
}

.border {
    height: 2px;
    width: 100%;
    margin: 15px 0;
}

.links-wrap {
    color: #2a5885;
    /*margin-bottom: 24px;*/
}

.links-wrap > p:not(:last-child) {
    margin-bottom: 16px;
}

.links-wrap > p:nth-child(9) {
    margin-bottom: 32px;
}

.links-wrap > p:last-child {
    line-height: 22px;
}

.links-wrap > p > a {
    color: #0081ff;
    text-decoration: underline;
}

.links-wrap > p > a:hover {
    color: #2a5885;
    text-decoration: underline;
}

.monitoring-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    flex-shrink: 0;
    align-items: center;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    overflow-y: hidden;
    overflow-x: auto;
    margin-top: 16px;
}

.monitoring-wrap > a {
    width: 88px;
    height: 32px;
}

.monitoring-wrap > a:not(:last-child) {
    margin-right: 16px;
}

.monitoring-wrap > a > img {
    width: 100%;
    height: 100%;
}

.refferalLink {
    text-decoration: underline;
    color: $link;
    transition: all 0.32s;
    margin-top: 1rem;
    &:hover {
        color: #2a5885;
    }
}

.addMonitoring {
    margin-top: 1.25rem;
}

.title-wrap {
    min-height: 128px;
    display: flex;
    align-items: center;
}

@media (max-width: 739px) {
    .options {
        flex-direction: column;
    }
    //.options .option:not(:last-child) {
    //    margin: 0 0 32px 0;
    //}
    .border {
        margin: 10px 0;
    }
}
