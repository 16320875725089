@import "@/assets/styles/styles.scss";



























.buttons-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
}
.buttons-wrapper .button {
    padding: 8px 16px;
}
.buttons-wrapper .button.outline {
    padding: 7px 16px;
    margin-right: 16px;
}
