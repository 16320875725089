@import "@/assets/styles/styles.scss";




















.rejectedOrderContentDesc {
    font-size: 14px;
    line-height: 20px;
    // background-color: $white;
    padding: 16px;
}

.rejectedOrderContentDesc > p {
    margin-bottom: 16px;
}
.rejectedOrderContentDesc_import {
    font-size: 14px;
    line-height: 20px;
    // background-color: $white;
    padding: 0px 16px;
    margin-top: 5px;
    font-weight: 600;
}
