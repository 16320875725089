@import "@/assets/styles/styles.scss";


























































































.loading {
    position: fixed;
    background: #fff8ef;
    z-index: 999;
    top: 50%;
    left: 50%;
    overflow: hidden;
    transform: translate(-50%, -50%);
    width: -webkit-fill-available;
    height: -webkit-fill-available;
}

.loader {
    border: 14px solid #eee8e1; /* Light grey */
    border-top: 14px solid $orange; /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 1.8s linear infinite;
    position: fixed;
    top: calc(50% - 60px);
    left: calc(50% - 60px);
    z-index: 1000;
    overflow: hidden;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.12%), 0 0 20px 0 rgba(0, 0, 0, 0.12%), 0 0 20px 0 rgba(0, 0, 0, 0.12%);
}

.percent {
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 1000;
    overflow: hidden;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    font-size: 20px;
    font-weight: 500;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@media (max-width: 739px) {
    .loader {
        border: 10px solid #dddddd;
        border-top: 10px solid $orange;
        border-radius: 50%;
        width: 120px;
        height: 120px;
        animation: spin 1.8s linear infinite;
    }
}
