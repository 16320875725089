@import "@/assets/styles/styles.scss";











































































































.orderChangeDirection {
    background-color: #0000000d;
    padding: 18px 22px;
    border-radius: 8px;
    margin-top: 20px;
}
.orderChangeDirection_desc {
    text-align: center;
    margin-top: 20px;
}

.orderChangeDirection_btns {
    display: flex;
    margin-top: 20px;
    justify-content: center;
    align-items: center;
}

.orderChangeDirection_btns button:first-child {
    margin-right: 10px;
}
.orderChangeDirection_btns button:last-child {
    margin-left: 10px;
}
