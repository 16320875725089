@import "@/assets/styles/styles.scss";











































.select__net {
    // border-radius: 5px;
    // background-color: rgba(128, 128, 128, 0.15);
    // padding: 3px 5px;
    cursor: pointer;
    font-size: 14px;
    position: relative;
    margin-left: 5px;
}
.select__net-selected {
    border-radius: 5px;
    background-color: #e9e7e7;
    padding: 2px 4px;
}

.select__net-options {
    max-width: max-content;
    position: absolute;
    top: 0;
    border-radius: 5px;
    background-color: #e9e7e7;
    overflow: hidden;
    // padding: 3px 5px;
}

.select__net-options__item {
    min-width: max-content;
    padding: 2px 4px;
    margin-bottom: 2px;
    width: 100%;
    
    transition: all 0.2s;
}

.select__net-options__item:hover {
    // min-width: max-content;
    // padding: 2px 4px;
    // margin-bottom: 2px;
    background-color: #ff88002b;
}


