@import "@/assets/styles/styles.scss";
















































































.captcha-section {
    .captch-top {
        display: flex;
        align-items: center;
        margin-top: 10px;
        .captcha-info {
            margin-left: 10px;
            font-family: "Whitney", sans-serif;
            color: #565656; } } }
