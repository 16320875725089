$border-sm: 10px;
$border-norm: 16px;

$border-lg: 1px solid rgba(0, 0, 0, 0.12);
$border-dark : 1px solid rgba(0, 0, 0, 0.32);

// Colors

$primary-light: #faa640;

$auth-wrapper: #fff;
$header-text: #dae2ea;
$page-wrap: #FFF8EF;




$link: #1877F2;

$dark-orange: #c26700;
$code: #ec8911;
$orange: #ff8c00;
$white: #ffffff;
$black: #000000;
$statusText : #1C1C1C;

$cur--hover : #ff88002b;
$cur--select : #ff88002b;

$grey-text: #797979;
$grey-lg-text:#727272;

$step-sub-header: #4f4f4f;

$cur-name: #404040;


$green: #4BBD5C;
$red: #D0021B;
$grafit: #2D3540;
$yellow: #FFC200;
$blue: #006FFF;
$grey: #f5f5f5;